import { useState } from "react";
import { assets } from "../assets/assets";

const flappyImage = assets.flappyBird;

export default function FlappyBird() {
    const [selectedImage, setSelectedImage] = useState(null);

    // Fonction pour fermer l'image agrandie
    const closeImage = () => setSelectedImage(null);

    return (
        <div className="container mx-auto px-4 py-16 text-gray-100 bg-gray-900 min-h-screen">
            <div className="max-w-5xl mx-auto p-8 rounded-lg shadow-lg bg-gray-800 border border-gray-700">
                <h2 className="text-5xl font-bold mb-6 text-center text-indigo-400">FlappyBird Game</h2>
                <p className="text-lg leading-relaxed mb-8 text-gray-300">
                    A Java-coded version of the classic Flappy Bird, offering simple yet addictive gameplay.
                </p>

                <div className="mb-10">
                    <h3 className="text-3xl font-semibold mb-6 text-teal-400">Interface</h3>
                    <div className="flex items-center justify-center">
                        <div className="relative group">
                            <img
                                src={flappyImage}
                                alt={"FlappyBirdImage"}
                                className="w-full h-full object-cover rounded-3xl shadow-lg transition-transform duration-300 transform hover:scale-105"
                                onClick={() => setSelectedImage(flappyImage)}
                            />
                        </div>
                    </div>
                    <p className="text-lg mb-4 mt-2">
                        <strong>Technologies utilisées : </strong>Java, Javax<br/>
                        <strong>Github code : </strong><a className="text-blue-400 hover:text-blue-300" href="https://github.com/walidmadad/FlappyBirdGame">FlappyBird Game</a>
                    </p>
                </div>
                {selectedImage && (
                    <div className="fixed inset-0 bg-black bg-opacity-80 flex items-center justify-center z-50">
                        <div className="relative">
                            <img src={selectedImage} alt="Selected" className=" h-full max-h-96 rounded-lg shadow-lg" />
                            <button onClick={closeImage} className="absolute top-2 right-2 text-gray-600 text-4xl font-bold hover:text-gray-800">&times;</button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}